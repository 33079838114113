// Include Sass Files
@import "inc/variables/colors.scss";
@import "inc/variables/fonts.scss";
@import "inc/variables/others.scss";

@import "inc/mixins/media-query.scss";
@import "inc/mixins/flex.scss";


@include for-phone-up{

    #header{
        padding-top: 30px;
        padding-bottom: 30px;

        .quiz{
            width: 100%;
            height: 16px;
            overflow: hidden;
            white-space: nowrap;
            position: relative;

            .track {
                position: absolute;
                white-space: nowrap;
                will-change: transform;
                animation: marquee 20s linear infinite;
            }

            p{
                font-size: 12px;
                color: #FFF;
                height: 12px;
                font-family: "HelveticaNeueRegular";
                margin: 0 auto;
            }
        }

        .title{
            width: 100px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
            margin-top: 20px;
            position: relative;

            h2{
                font-size: 26px;
                letter-spacing: 1.5px;
                color: #FFF;
                font-weight: bold;
                position: absolute;
                right: -25px;
                font-family: "HelveticaNeueMedium";
            }
        }
    }
}

@include for-tablet-up{


}

@include for-desktop-up{

    #header{
        padding-top: 30px;
        padding-bottom: 60px;
    }


}

@include for-tv-up{

    .container{
        max-width: 1200px;
    }

}
